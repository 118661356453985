import React from 'react';
import './waterfallAnimation.css';

function generateDemoData() {
    const games = ["Slots", "Live Casino"];
    const players = ["Player1", "Player2", "Player3", "Player4", "Player5", "Player6", "Player7", "Player8", "Player9", "Player10"];
    
    const demoData = [];
    for (let i = 0; i < 50; i++) {
        demoData.push({
            game: games[Math.floor(Math.random() * games.length)],
            id: Math.floor(Math.random() * 100000),
            player: players[Math.floor(Math.random() * players.length)],
            bet: (Math.random() * 1000).toFixed(2),
            payout: (Math.random() * 1000).toFixed(2)
        });
    }
    return demoData;
}

const demoData = generateDemoData();

function Winnings() {
    return (
        <div className='relative h-[400px] overflow-y-auto rounded-md border'>
            <table className="w-full caption-bottom text-sm grid">
  <thead className="[&_tr]:border-b sticky top-0 z-10 grid bg-white">
    <tr className="text-hsl(240, 4%, 46%) border-b bg-white transition-colors hover:bg-muted/50 flex w-full">
      <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground flex items-center w-[25%]">Game</th>
      <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground flex items-center w-[25%]">Player ID</th>
      <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground flex items-center w-[25%]">Name</th>
      <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground flex items-center w-[25%]">Bet</th>
      <th className="h-12 px-4 text-left align-middle font-medium text-muted-foreground flex items-center w-[25%]">Payout</th>
    </tr>
  </thead>
  <tbody className="relative grid h-[5148px] md:h-[1146px] overflow-hidden">
    {demoData.map((row, index) => (
      <tr
        key={index}
        className="transform translate-y-full opacity-0 transition-transform transition-opacity duration-500 hover:bg-muted/50 flex w-full"
        style={{ animation: 'fadeInUp 0.5s forwards', animationDelay: `${index * 0.1}s` }} // Animation delay for staggering effect
      >
        <td className="p-4 align-middle flex items-center w-[25%]">
          <a className="flex items-center gap-2 text-poly-yellow" href={`/en/game/${row.game.toLowerCase().replace(/\s+/g, '-')}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <rect width="24" height="24" fill="#FFDB5B" rx="6"></rect>
              <path fill="#3D2F00" d="M17.8434 4.14129C17.1126 3.41053 16.1215 3 15.088 3H8.91195c-1.03344 0-2.02456.41053-2.75532 1.14129L4.14129 6.15663C3.41054 6.88739 3 7.87851 3 8.91195V15.088c0 1.0335.41053 2.0246 1.14129 2.7554l2.01534 2.0153C6.88739 20.5895 7.87851 21 8.91195 21H15.088c1.0335 0 2.0246-.4105 2.7554-1.1413l2.0153-2.0153C20.5895 17.1126 21 16.1215 21 15.088V8.91195c0-1.03344-.4105-2.02456-1.1413-2.75532l-2.0153-2.01534ZM6.49911 10.6277c-.76086.7608-.76086 1.9944 0 2.7553l4.13299 4.133c.7609.7608 1.9945.7608 2.7553 0l4.133-4.133c.7609-.7609.7609-1.9945 0-2.7553l-4.133-4.13303c-.7608-.76086-1.9944-.76086-2.7553 0L6.49911 10.6277Z" clipRule="evenodd"></path>
            </svg>
            <span className="hidden lg:block">{row.game}</span>
          </a>
        </td>
        <td className="p-4 align-middle w-[25%]">
          <a target="_blank" rel="noopener noreferrer" href={`https://polygonscan.com/tx/${row.id}`}>
            {row.id}
          </a>
        </td>
        <td className="p-4 align-middle flex items-center w-[25%]">
          <a href={`/en/player/${row.player}`}>
            <div className="flex items-center gap-2">
              <div className="flex h-6 w-6 items-center justify-center overflow-hidden rounded-full bg-rgb-95-208-243">🐳</div>
              <p className="hidden xl:block">{row.player}</p>
            </div>
          </a>
        </td>
        <td className="p-4 align-middle w-[25%]">
          <p className="flex items-center gap-2">{row.bet}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="24" height="24" rx="12" fill="#6100FF"></rect>
              <path d="M15.4186 9.6977C15.1744 9.55816 14.8604 9.55816 14.5814 9.6977L12.6279 10.8489L11.3023 11.5814L9.3837 12.7326C9.13952 12.8721 8.82556 12.8721 8.54649 12.7326L7.04649 11.8256C6.80231 11.6861 6.62789 11.407 6.62789 11.093V9.34886C6.62789 9.06979 6.76742 8.79072 7.04649 8.6163L8.54649 7.74421C8.79068 7.60468 9.10463 7.60468 9.3837 7.74421L10.8837 8.65119C11.1279 8.79072 11.3023 9.06979 11.3023 9.38375V10.5349L12.6279 9.76747V8.58142C12.6279 8.30235 12.4884 8.02328 12.2093 7.84886L9.41859 6.20933C9.1744 6.06979 8.86045 6.06979 8.58138 6.20933L5.72091 7.88374C5.44184 8.02328 5.30231 8.30235 5.30231 8.58142V11.8605C5.30231 12.1396 5.44184 12.4186 5.72091 12.593L8.54649 14.2326C8.79068 14.3721 9.10463 14.3721 9.3837 14.2326L10.8837 13.4156L11.3023 12.6936L12.6279 11.9691V12.3482C12.6279 12.6621 12.8023 12.9402 13.0465 13.0797L15.4186 14.7326C15.6628 14.8721 15.9747 14.8721 16.2538 14.7326L19.0282 13.2538C19.2724 13.1143 19.4129 12.8353 19.4129 12.5198V10.4737C19.4129 10.1582 19.2724 9.87915 19.0282 9.6977L15.4186 9.6977Z" fill="white"></path>
            </svg>
          </p>
        </td>
        <td className="p-4 align-middle w-[25%]">
          <p className="flex items-center gap-2">
            {row.payout}
          </p>
        </td>
      </tr>
    ))}
  </tbody>
</table>
        </div>
    );
}

export default Winnings;
