import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../Components/Auth/AuthContext'; 
import euro from '../../Assets/euroBlue.webp';
import axios from 'axios';
import { getDatabase, ref, onValue } from 'firebase/database';

const Users = () => {
  const { currentUser, balance, email, uuid,  fwinBackendUrl, fetchUserBalance} = useAuth();
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [onlineUserData, setOnlineUserData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [twoFaModel, setTwoFaModel] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState();
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [otp, setOtp] = useState(Array(6).fill(''));
  const inputRefs = useRef([]);
  const [activeTab, setActiveTab] = useState('all');
  const [openIndex, setOpenIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");

  // Filtered data based on the search query
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/').map(Number); // Split and convert to numbers
    return new Date(year, month - 1, day); // Create a Date object (month is 0-based)
  };
  
  // Check if the user is new (registered in the last 24 hours)
  const isNewUser = (createdAt) => {
    const userDate = parseDate(createdAt);
    const now = new Date();
    const diffInHours = (now - userDate) / (1000 * 60 * 60); // Difference in hours
    return diffInHours <= 24;
  };
  
  const filteredData = userData
    .filter(user =>
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => parseDate(b.createdAt) - parseDate(a.createdAt));

  // Calculate indices for slicing
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  // Handle next and previous
  // Handle next and previous
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };




  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${fwinBackendUrl}/admin/users`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json(); 
        setUserData(data);
        
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);
  useEffect(() => {
    const fetchOnlineUsers = async () => {
      const db = getDatabase();
      const usersRef = ref(db, 'users'); // Update with the correct path in your database
  
      // Fetch the data from Firebase Realtime Database
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
  
        // Initialize an empty array to store online users' emails
        const onlineEmails = [];
  
        // Loop through the data to find users with online status set to true
        for (const userId in data) {
          if (data[userId].online) {
            const email = data[userId].email;
            if (email) {  // Only push emails that are not undefined or null
              onlineEmails.push(email);
            }
          }
        }
  
        // Update the state with the list of online user emails
        setOnlineUsers(onlineEmails);
        const userDataResponse  = userData;
        const filteredUsers = userDataResponse.filter(user => 
          onlineUsers.includes(user.email)
        );
        setOnlineUserData(filteredUsers);
      });
    };
    fetchOnlineUsers();
  }, []); 
    // Initialize Firebase Realtime Database
  
   


  const handleEditClick = (user, index) => {
    setSelectedUser(user);
    setIsModalOpen(true);
    
  };
  const handleUserModel = async (user, index) => {
    setSelectedUser(user);
    setIsUserModalOpen(true);
    try {
      // Fetch user data using the email from the user parameter
      const response = await fetch(`${fwinBackendUrl}/admin/user-data/${user.email}`);
  
      if (!response.ok) {
        throw new Error('User data not found or server error');
      }
  
      const data = await response.json();
      
      // Once the data is fetched, you can use it as needed.
      // For example, you can set it to the state to display in the modal.
      setSelectedUserData(data);
    } catch (error) {
      // Handle any error in fetching user data
      console.error("Error fetching user data", error);
    }
    
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setTwoFaModel(false);
    clearOtpFields();
  };
  const closeUserModal = () => {
    setIsUserModalOpen(false);
  };
  const openTwoFaModel = async () => {
    try {
      // Reset OTP state and clear input fields
      setOtp(Array(6).fill('')); // Reset OTP state
      setTwoFaModel(false); // Ensure the modal is closed before reopening
      setTimeout(() => {
        setTwoFaModel(true); // Open the Two-Factor Authentication model
        clearOtpFields();
      }, 0);

      // Make the OTP request
      const response = await axios.get(`${fwinBackendUrl}/admin/send-otp`, {
        params: {
          email: currentUser.email, // Pass the user's email or other necessary parameter
        },
      });

      // Handle successful OTP request
      console.log('OTP sent successfully:', response.data);
      // You might want to display a success message or perform other actions

    } catch (err) {
      // Handle error in OTP request
      console.error('Error sending OTP:', err);
      // Optionally, you might want to provide feedback to the user
    }
  };
  // Function to clear OTP input fields
  const clearOtpFields = () => {
    // Clear OTP inputs by directly manipulating the refs
    inputRefs.current.forEach(ref => {
      if (ref) {
        ref.value = '';
      }
    });
  };
  const handleNumberChange = (number) => {
    setSelectedNumber(number);
  };
  const handle2FaChange = (e, index) => {
    const value = e.target.value;
    if (value.match(/[0-9]/)) {
      // Update OTP state
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input if not the last one
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      // Handle invalid input (not a number)
      e.target.value = '';
    }
  };
  // Handle the submit of the OTP form
  const handle2FaSubmit = async (e) => {
    e.preventDefault();
    const otpString = otp.join('');

    try {
      const response = await axios.get(`${fwinBackendUrl}/admin/verify-otp`, {
        params: {
          email: currentUser.email, // Replace with actual email
          otp: otpString,
          amount : Number(selectedNumber),
          user : selectedUser.email
        },
      });
      alert('OTP is valid');
    } catch (error) {
      alert('Invalid or expired OTP');
    }
    clearOtpFields();
  };
  // Focus on the previous input when current one is empty
  const handle2FaKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };
  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  useEffect(() => {
    // Initialize inputRefs
    inputRefs.current = inputRefs.current.slice(0, 6);
  }, [inputRefs.current]);

  if (loading) return <div>Loading...</div>; 
  if (error) return <div>Error: {error}</div>;

   // Function to handle tab change
   const handleTabChange = (tab) => {
    setActiveTab(tab);
    // Reset pagination when switching tabs
    const fetchOnlineUsers = async () => {
      const db = getDatabase();
      const usersRef = ref(db, 'users'); // Update with the correct path in your database
  
      // Fetch the data from Firebase Realtime Database
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
  
        // Initialize an empty array to store online users' emails
        const onlineEmails = [];
  
        // Loop through the data to find users with online status set to true
        for (const userId in data) {
          if (data[userId].online) {
            const email = data[userId].email;
            if (email) {  // Only push emails that are not undefined or null
              onlineEmails.push(email);
            }
          }
        }
  
        // Update the state with the list of online user emails
        setOnlineUsers(onlineEmails);
        const userDataResponse  = userData;
        const filteredUsers = userDataResponse.filter(user => 
          onlineUsers.includes(user.email)
        );
        setOnlineUserData(filteredUsers);
      });
    };
    fetchOnlineUsers();
    
   };
   const refreshHistory = () => {
    // Define the endpoints with their extensions
    const endpoints = [
      `${fwinBackendUrl}/admin/live-history`,
      `${fwinBackendUrl}/admin//slots-history`,
    ];
  
    // Use Promise.all to fetch all endpoints simultaneously
    Promise.all(
      endpoints.map((endpoint) =>
        fetch(endpoint).then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json(); // Parse JSON from response
        })
      )
    )
      .then((results) => {
        console.log("Fetched Data:");
        results.forEach((data, index) => {
          console.log(`Response from ${endpoints[index]}:`, data);
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
      });
   };
  
  

  return (
    <>
    <section>
                <div className='space-y-5'>
                    <div className="flex items-center gap-2">
                        <div className="flex h-6 w-6 items-center justify-center rounded-md border">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-club h-4 w-4"
                            >
                                <path d="M17.28 9.05a5.5 5.5 0 1 0-10.56 0A5.5 5.5 0 1 0 12 17.66a5.5 5.5 0 1 0 5.28-8.6Z"></path>
                                <path d="M12 17.66L12 22"></path>
                            </svg>
                        </div>
                        <h3>Users Overview</h3>
                    </div>
                    <div className='flex'>
                        <div className='flex-1'>
                          <div className='flex items-center'>
                              <div className='themeSwitcherTwo p-2 shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-gray-200'>
                            <input
                                type='checkbox'
                                className='sr-only'
                                checked={activeTab === 'all'}
                                onChange={() => handleTabChange(activeTab === 'live' ? 'all' : 'live')}
                            />
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${activeTab === 'all' ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'}`}
                                onClick={() => handleTabChange('all')}
                            >
                                All Users
                            </span>
                            <span
                                className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium ${activeTab === 'live' ? 'text-primary bg-[#f4f7ff]' : 'text-body-color'}`}
                                onClick={() => handleTabChange('live')}
                            >
                                Live Players
                            </span>
                           
                              </div>
                          </div>
                        </div>
                        <div className='flex-none'>
                        <div className='flex'>
                        <button
                         onClick={refreshHistory}
                         type="button" class="flex gap-2 items-center text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
                                </svg>
                            Refresh
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
    </section>
    <section>
      <div className='py-4'>
{activeTab === 'all' && (
                      <div>
                        <div className='rounded-lg  p-1 bg-white'>
                        <div class="py-3 px-4 border rounded-lg divide-y divide-gray-200 dark:border-neutral-700 dark:divide-neutral-700">
          <div class="relative max-w-xs ">
            <label class="sr-only">Search</label>
            <input type="text" name="hs-table-with-pagination-search" id="hs-table-with-pagination-search" class="py-2 px-3 ps-9 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" 
            placeholder="Search by email"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1); // Reset to first page on filter change
            }}
             />
            <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-3">
              <svg class="size-4 text-gray-400 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="11" cy="11" r="8"></circle>
                <path d="m21 21-4.3-4.3"></path>
              </svg>
            </div>
          </div>
        </div>
                            <table className="min-w-full  divide-y divide-gray-200 bg-white border border-gray-300 rounded-lg">
                                <thead className="bg-gray-50">
            <tr className="w-full border-b">
              <th className="px-6 py-4  text-left text-sm font-medium ">UserID</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Email</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Player</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Country</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Balance</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Total Bet</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Total Win</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">RTP</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Joined</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Edit</th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {currentRows.map((user, index) => (
              <tr key={index} className="border font-normal text-left">
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.userId}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">
                {isNewUser(user.createdAt) && (
        <span className="text-white bg-red-500 px-2 py-1 rounded text-xs font-semibold me-2">
          New
        </span>
      )}
                  {user.email}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.player}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.country}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">€{user.balance}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.total_bet}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.total_win}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.rtp}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.createdAt}</td>

                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500 curson-pointer">
                  <div className='flex text-blue-600 gap-4'>
                    <div className='edit-icon cursor-pointer' onClick={() => handleEditClick(user, index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                    </svg>
                    </div>
                    <div className='info-icon cursor-pointer' onClick={() => handleUserModel(user, index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                    </svg>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <button
          className="px-4 py-2 bg-gray-200 text-gray-600 rounded-lg"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <p className="text-sm text-gray-600">
          Page {currentPage} of {totalPages}
        </p>
        <button
          className="px-4 py-2 bg-gray-200 text-gray-600 rounded-lg"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      </div> 

      {isModalOpen && (
  <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-gray-900 bg-opacity-50">
    <div className="relative p-4 w-full max-w-2xl">
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {twoFaModel ? 'Two-Factor Authentication' : 'Edit User'}
          </h3>
        </div>
        {twoFaModel ? (
          <div className="p-4">
            <p className='text-md text-black text-center font-light'>Authenticate to add balance to user</p>
            <div>

            <form className="max-w-sm mx-auto my-4" onSubmit={handle2FaSubmit}>
      <div className="flex mb-2 space-x-2 rtl:space-x-reverse justify-center">
        {otp.map((value, index) => (
          <div key={index}>
            <label htmlFor={`code-${index + 1}`} className="sr-only">
              {`Code ${index + 1}`}
            </label>
            <input
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handle2FaChange(e, index)}
              onKeyDown={(e) => handle2FaKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
              id={`code-${index + 1}`}
              className="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            />
          </div>
        ))}
      </div>
      <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
        Please introduce the 6-digit code we sent via email.
      </p>
      <button
        type="submit"
        className="mt-6 text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        Confirm
      </button>
            </form>
            </div>
           
          </div>
        ) : (
          <div className='p-4'>
            {selectedUser && (
              <>
                <p className="text-lg font-bold mb-4 text-left text-gray-500 font-light">
                  User ID: <span className='text-xs text-black font-light'>{selectedUser.userId}</span>
                </p>
                <p className="text-lg font-bold mb-4 text-left text-gray-500 font-light">
                  Email: <span className='text-xs text-black font-light'>{selectedUser.email}</span>
                </p>
                <div className='flex items-center'>
                  <p className="text-lg font-bold text-left text-gray-500 font-light">
                    User balance: <span className='text-lg text-black font-light'>{selectedUser.balance}</span>
                  </p>
                  <img className='h-[22px]' src={euro} alt="euro" />
                </div>
              </>
            )}
            <div className="p-4 text-left">
              <label htmlFor="balance" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Add balance
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={selectedNumber}
                onChange={(e) => handleNumberChange(e.target.value)}
              />
            </div>
            <div className='flex w-3/5 px-4 pb-8'>
            <div role="radiogroup" aria-required="false" dir="ltr" class="flex justify-between gap-1" tabindex="0" >
                                                         
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7b:-form-item">
                                                                 <button onClick={() => handleNumberChange(5)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="1" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7b:-form-item" aria-describedby=":r7b:-form-item-description" aria-invalid="false" tabindex="-1" data-radix-collection-item="">
                                                                 </button>
                                                                 <input aria-hidden="true" tabindex="-1" type="radio" value="1" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' />
                                                                 <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 5 ? 'bg-black text-white' : ''}`}>
                                                                     <h3 class="text-center text-xl">
                                                                         5
                                                                     </h3>
                                                                 </div>
                                                             </label>
                                                         </div>
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7d:-form-item">
                                                                 <button onClick={() => handleNumberChange(10)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="2" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7d:-form-item" aria-describedby=":r7d:-form-item-description" aria-invalid="false" tabindex="-1" data-radix-collection-item="">
                                                                     </button>
                                                                     <input aria-hidden="true" tabindex="-1" type="radio" value="2" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' />
                                                                     <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 10 ? 'bg-black text-white' : ''}`}>
                                                                         <h3 class="text-center text-xl">10</h3>
                                                                     </div>
                                                             </label>
                                                         </div>
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7f:-form-item">
                                                                 <button onClick={() => handleNumberChange(20)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="3" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7f:-form-item" aria-describedby=":r7f:-form-item-description" aria-invalid="false" tabindex="-1" data-radix-collection-item=""></button>
                                                                 <input aria-hidden="true" tabindex="-1" type="radio" value="3" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' />
                                                                 <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 20 ? 'bg-black text-white' : ''}`}>
                                                                     <h3 class="text-center text-xl">20</h3>
                                                                 </div>
                                                             </label>
                                                         </div>
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7h:-form-item">
                                                                 <button onClick={() => handleNumberChange(50)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="4" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7h:-form-item" aria-describedby=":r7h:-form-item-description" aria-invalid="false" tabindex="0" data-radix-collection-item="">
                                                                     <span data-state="checked" class="flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle h-2.5 w-2.5 fill-current text-current"><circle cx="12" cy="12" r="10"></circle></svg></span></button><input aria-hidden="true" tabindex="-1" type="radio" value="4" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' checked="" />
                                                                     <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 50 ? 'bg-black text-white' : ''}`}>
                                                                         <h3 class="text-center text-xl">50</h3>
                                                                     </div>
                                                             </label>
                                                         </div>
                                                     </div>
            </div>
          </div>
        )}
        <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button
            onClick={twoFaModel ? closeModal : openTwoFaModel}
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {twoFaModel ? 'Close' : 'Save'}
          </button>
          {!twoFaModel && (
            <button
              onClick={closeModal}
              type="button"
              className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
      )}
      {isUserModalOpen && (
  <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-gray-900 bg-opacity-50">
    <div className="relative p-4 w-full max-w-2xl">
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <div className="flex justify-between items-center border-b pb-2">
              <h2 className=" p-4 text-lg font-semibold text-gray-800 dark:text-white">
                User Info
              </h2>
            </div>
            {/* Modal Body */}
                      <div className="my-4 px-4 space-y-3 text-sm text-left">
                      <div id="accordion-collapse" data-accordion="collapse">
      {[1, 2, 3].map((index) => (
        <div key={index}>
          <h2 id={`accordion-collapse-heading-${index}`}>
            <button
              type="button"
              className="flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200  focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3"
              onClick={() => handleToggle(index)}
              aria-expanded={openIndex === index ? "true" : "false"}
              aria-controls={`accordion-collapse-body-${index}`}
            >
                { index === 1 && "Basic Info"}
                {index === 2 && "Financial Summary"}
                {index === 3 && "Bets Summary"}
              <svg
                className={`w-3 h-3 rotate-180 shrink-0 ${openIndex === index ? 'rotate-0' : ''}`}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5L5 1 1 5"
                />
              </svg>
            </button>
          </h2>
          <div
  id={`accordion-collapse-body-${index}`}
  className={`max-h-[60vh] overflow-y-auto p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900 ${openIndex === index ? '' : 'hidden'}`}
  aria-labelledby={`accordion-collapse-heading-${index}`}
>
  {index === 1 && selectedUserData?.user_info && (
    <div>
      <div className='flex justify-between'>
        <div><p className='text-gray-500'>UserId : &nbsp; </p></div>
        <div><p>{selectedUserData.user_info.userId}</p></div>
      </div>
      <div className='flex justify-between'>
        <div><p className='text-gray-500'>Email : &nbsp; </p></div>
        <div><p>{selectedUserData.user_info.email}</p></div>
      </div>
      <div className='flex justify-between'>
        <div><p className='text-gray-500'>Name : &nbsp; </p></div>
        <div><p>{`${selectedUserData.user_info.firstname} ${selectedUserData.user_info.lastname}`}</p></div>
      </div>
      <div className='flex justify-between'>
        <div><p className='text-gray-500'>Country : &nbsp; </p></div>
        <div><p>{selectedUserData.user_info.country}</p></div>
      </div>
      <div className='flex justify-between'>
        <div><p className='text-gray-500'>Phone : &nbsp; </p></div>
        <div><p>{selectedUserData.user_info.phone}</p></div>
      </div>
      <div className='flex justify-between'>
        <div><p className='text-gray-500'>Balance : &nbsp; </p></div>
        <div><p>{selectedUserData.financial?.balance}</p></div>
      </div>
      <div className='flex justify-between'>
        <div><p className='text-gray-500'>RTP : &nbsp; </p></div>
        <div><p>{selectedUserData.user_info.RTP}</p></div>
      </div>
      <div className='flex justify-between'>
        <div><p className='text-gray-500'>Total bet amount: &nbsp; </p></div>
        <div><p>{selectedUserData.financial?.total_debit.toFixed(2)}</p></div>
      </div>
      <div className='flex justify-between'>
        <div><p className='text-gray-500'>Total win amount : &nbsp; </p></div>
        <div><p>{selectedUserData.financial?.total_credit}</p></div>
      </div>
      <div className='flex justify-between'>
        <div><p className='text-gray-500'>Registered : &nbsp; </p></div>
        <div><p>{selectedUserData.user_info.joined}</p></div> 
      </div>
    </div>
  )}

{index === 2 && selectedUserData && selectedUserData.financial ? (
  <div>
    {/* Deposits Table */}
    <h3 className="text-xl font-semibold mb-4">Deposits</h3>
    {selectedUserData.financial.deposits.length > 0 ? (
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Amount</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Date</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Method</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Transaction ID</th>
          </tr>
        </thead>
        <tbody>
          {selectedUserData.financial.deposits.map((deposit, index) => (
            <tr key={index} className="border-t border-gray-200">
              <td className="px-4 py-2">{deposit.amount}</td>
              <td className="px-4 py-2">{new Date(deposit.date).toLocaleDateString()}</td>
              <td className="px-4 py-2">{deposit.method}</td>
              <td className="px-4 py-2">{deposit.transactionId}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p className="text-gray-500">No deposits found.</p>
    )}

    {/* Withdrawals Table */}
    <h3 className="text-xl font-semibold mt-6 mb-4">Withdrawals</h3>
    {selectedUserData.financial.withdrawals.length > 0 ? (
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Amount</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Date</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Wallet</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Transaction ID</th>
          </tr>
        </thead>
        <tbody>
          {selectedUserData.financial.withdrawals.map((withdrawal, index) => (
            <tr key={index} className="border-t border-gray-200">
              <td className="px-4 py-2">{withdrawal.amount}</td>
              <td className="px-4 py-2">{new Date(withdrawal.date).toLocaleDateString()}</td>
              <td className="px-4 py-2">{withdrawal.wallet}</td>
              <td className="px-4 py-2">{withdrawal.transactionId}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p className="text-gray-500">No withdrawals found.</p>
    )}

    {/* Bonus Table */}
    <h3 className="text-xl font-semibold mt-6 mb-4">Bonus</h3>
    {selectedUserData.financial.bonus.length > 0 ? (
      <table className="min-w-full table-auto border-collapse border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Type</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Amount</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Description</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Wagering</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Wagering Progress</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Status</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Created At</th>
            <th className="px-4 py-2 text-left font-medium text-gray-700">Expiry</th>
          </tr>
        </thead>
        <tbody>
          {selectedUserData.financial.bonus.map((bonus, index) => (
            <tr key={index} className="border-t border-gray-200">
              <td className="px-4 py-2">{bonus.type}</td>
              <td className="px-4 py-2">{bonus.amount}</td>
              <td className="px-4 py-2">{bonus.description}</td>
              <td className="px-4 py-2">{bonus.wagering}</td>
              <td className="px-4 py-2">{bonus.wageringProgress}</td>
              <td className="px-4 py-2">{bonus.status}</td>
              <td className="px-4 py-2">{new Date(bonus.createdAt).toLocaleDateString()}</td>
              <td className="px-4 py-2">{new Date(bonus.expiry).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p className="text-gray-500">No bonuses found.</p>
    )}
  </div>
) : (
  <p className="text-red-500"></p>
)}



  {index === 3 && selectedUserData?.bets && (
    <div className="overflow-x-auto">
    {/* Live Game History Table */}
    <h3 className='font-medium text-gray-600 dark:text-gray-400 mb-2'>Live Game History</h3>
    <div className="max-h-[360px] overflow-y-auto">
      <table className="min-w-full table-auto border-collapse">
        <thead className="bg-gray-200 dark:bg-gray-800">
          <tr>
            <th className="px-4 py-2 border-b">Game Type</th>
            <th className="px-4 py-2 border-b">Bet Amount</th>
            <th className="px-4 py-2 border-b">Win Amount</th>
            <th className="px-4 py-2 border-b">Status</th>
            <th className="px-4 py-2 border-b">Start Balance</th>
            <th className="px-4 py-2 border-b">End Balance</th>
          </tr>
        </thead>
        <tbody>
          {selectedUserData.bets.live_game_history?.map((bet) => (
            <tr key={bet.history_id} className="bg-white dark:bg-gray-900">
              <td className="px-4 py-2 border-b">{bet.type}</td>
              <td className="px-4 py-2 border-b">{bet.bet_money}</td>
              <td className="px-4 py-2 border-b">{bet.win_money}</td>
              <td className="px-4 py-2 border-b">{bet.txn_type}</td>
              <td className="px-4 py-2 border-b">{bet.user_start_balance}</td>
              <td className="px-4 py-2 border-b">{bet.user_end_balance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    {/* Slot Game History Table */}
    <h3 className='font-medium text-gray-600 dark:text-gray-400 mb-2 mt-4'>Slot Game History</h3>
    <div className="max-h-[360px] overflow-y-auto">
      <table className="min-w-full table-auto border-collapse">
        <thead className="bg-gray-200 dark:bg-gray-800">
          <tr>
            <th className="px-4 py-2 border-b">Game Type</th>
            <th className="px-4 py-2 border-b">Bet Amount</th>
            <th className="px-4 py-2 border-b">Win Amount</th>
            <th className="px-4 py-2 border-b">Status</th>
            <th className="px-4 py-2 border-b">Start Balance</th>
            <th className="px-4 py-2 border-b">End Balance</th>
          </tr>
        </thead>
        <tbody>
          {selectedUserData.bets.slot_game_history?.map((slotBet) => (
            <tr key={slotBet.history_id} className="bg-white dark:bg-gray-900">
              <td className="px-4 py-2 border-b">{slotBet.type}</td>
              <td className="px-4 py-2 border-b">{slotBet.bet_money}</td>
              <td className="px-4 py-2 border-b">{slotBet.win_money}</td>
              <td className="px-4 py-2 border-b">{slotBet.txn_type}</td>
              <td className="px-4 py-2 border-b">{slotBet.user_start_balance}</td>
              <td className="px-4 py-2 border-b">{slotBet.user_end_balance}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    {/* Coinflip Game History Table */}
    <h3 className='font-medium text-gray-600 dark:text-gray-400 mb-2 mt-4'>Coinflip Game History</h3>
    <div className="max-h-[360px] overflow-y-auto">
      <table className="min-w-full table-auto border-collapse">
        <thead className="bg-gray-200 dark:bg-gray-800">
          <tr>
            <th className="px-4 py-2 border-b">Bet Amount</th>
            <th className="px-4 py-2 border-b">Multiplier</th>
            <th className="px-4 py-2 border-b">Payout</th>
            <th className="px-4 py-2 border-b">Result</th>
          </tr>
        </thead>
        <tbody>
          {selectedUserData.bets.coinflip_history?.length === 0 ? (
            <tr>
              <td colSpan="4" className="px-4 py-2 border-b text-center">No coinflip history available.</td>
            </tr>
          ) : (
            selectedUserData.bets.coinflip_history?.map((coinflip) => (
              <tr key={coinflip.history_id} className="bg-white dark:bg-gray-900">
                <td className="px-4 py-2 border-b">{coinflip.bet}</td>
                <td className="px-4 py-2 border-b">{coinflip.multiplier}</td>
                <td className="px-4 py-2 border-b">{coinflip.payout}</td>
                <td className="px-4 py-2 border-b">{coinflip.status}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
</div>

  
  )}
</div>



        </div>
      ))}
    </div>
                      </div>
            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button
            onClick={closeUserModal}
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >Close
          </button>
          </div>
      </div>
    </div>
  </div>
    
      )}
                      </div>

)}
{activeTab === 'live' && (
                      <div>
                        <div className='rounded-lg  p-1 bg-white'>
                            <table className="min-w-full divide-y divide-gray-200 bg-white border border-gray-300 rounded-lg">
                                <thead className="bg-gray-50">
            <tr className="w-full border-b">
              <th className="px-6 py-4  text-left text-sm font-medium ">UserID</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Email</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Player</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Country</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Balance</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Total Bet</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Total Win</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">RTP</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Joined</th>
              <th className="px-6 py-4  text-left text-sm font-medium ">Edit</th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {onlineUserData.map((user, index) => (
              <tr key={index} className="border font-normal text-left">
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.userId}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.email}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.player}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.country}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">€{user.balance}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.total_bet}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.total_win}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.rtp}</td>
                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500">{user.createdAt}</td>

                <td className="px-6 py-3 whitespace-nowrap text-xs text-gray-500 curson-pointer">
                  <div className='flex text-blue-600 gap-4'>
                    <div className='edit-icon cursor-pointer' onClick={() => handleEditClick(user, index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                    </svg>
                    </div>
                    <div className='info-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                    </svg>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
  <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-gray-900 bg-opacity-50">
    <div className="relative p-4 w-full max-w-2xl">
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {twoFaModel ? 'Two-Factor Authentication' : 'Edit User'}
          </h3>
        </div>
        {twoFaModel ? (
          <div className="p-4">
            <p className='text-md text-black text-center font-light'>Authenticate to add balance to user</p>
            <div>

            <form className="max-w-sm mx-auto my-4" onSubmit={handle2FaSubmit}>
      <div className="flex mb-2 space-x-2 rtl:space-x-reverse justify-center">
        {otp.map((value, index) => (
          <div key={index}>
            <label htmlFor={`code-${index + 1}`} className="sr-only">
              {`Code ${index + 1}`}
            </label>
            <input
              type="text"
              maxLength="1"
              value={value}
              onChange={(e) => handle2FaChange(e, index)}
              onKeyDown={(e) => handle2FaKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
              id={`code-${index + 1}`}
              className="block w-9 h-9 py-3 text-sm font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            />
          </div>
        ))}
      </div>
      <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">
        Please introduce the 6-digit code we sent via email.
      </p>
      <button
        type="submit"
        className="mt-6 text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
      >
        Confirm
      </button>
            </form>
            </div>
           
          </div>
        ) : (
          <div className='p-4'>
            {selectedUser && (
              <>
                <p className="text-lg font-bold mb-4 text-left text-gray-500 font-light">
                  User ID: <span className='text-xs text-black font-light'>{selectedUser.userId}</span>
                </p>
                <p className="text-lg font-bold mb-4 text-left text-gray-500 font-light">
                  Email: <span className='text-xs text-black font-light'>{selectedUser.email}</span>
                </p>
                <div className='flex items-center'>
                  <p className="text-lg font-bold text-left text-gray-500 font-light">
                    User balance: <span className='text-lg text-black font-light'>{selectedUser.balance}</span>
                  </p>
                  <img className='h-[22px]' src={euro} alt="euro" />
                </div>
              </>
            )}
            <div className="p-4 text-left">
              <label htmlFor="balance" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Add balance
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={selectedNumber}
                onChange={(e) => handleNumberChange(e.target.value)}
              />
            </div>
            <div className='flex w-3/5 px-4 pb-8'>
            <div role="radiogroup" aria-required="false" dir="ltr" class="flex justify-between gap-1" tabindex="0" >
                                                         
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7b:-form-item">
                                                                 <button onClick={() => handleNumberChange(5)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="1" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7b:-form-item" aria-describedby=":r7b:-form-item-description" aria-invalid="false" tabindex="-1" data-radix-collection-item="">
                                                                 </button>
                                                                 <input aria-hidden="true" tabindex="-1" type="radio" value="1" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' />
                                                                 <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 5 ? 'bg-black text-white' : ''}`}>
                                                                     <h3 class="text-center text-xl">
                                                                         5
                                                                     </h3>
                                                                 </div>
                                                             </label>
                                                         </div>
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7d:-form-item">
                                                                 <button onClick={() => handleNumberChange(10)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="2" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7d:-form-item" aria-describedby=":r7d:-form-item-description" aria-invalid="false" tabindex="-1" data-radix-collection-item="">
                                                                     </button>
                                                                     <input aria-hidden="true" tabindex="-1" type="radio" value="2" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' />
                                                                     <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 10 ? 'bg-black text-white' : ''}`}>
                                                                         <h3 class="text-center text-xl">10</h3>
                                                                     </div>
                                                             </label>
                                                         </div>
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7f:-form-item">
                                                                 <button onClick={() => handleNumberChange(20)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="3" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7f:-form-item" aria-describedby=":r7f:-form-item-description" aria-invalid="false" tabindex="-1" data-radix-collection-item=""></button>
                                                                 <input aria-hidden="true" tabindex="-1" type="radio" value="3" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' />
                                                                 <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 20 ? 'bg-black text-white' : ''}`}>
                                                                     <h3 class="text-center text-xl">20</h3>
                                                                 </div>
                                                             </label>
                                                         </div>
                                                         <div class="space-y-2">
                                                             <label class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 [&amp;:has([data-state=checked])>div]:border-foreground [&amp;:has([data-state=checked])>div]:bg-foreground [&amp;:has([data-state=checked])>div]:text-background" for=":r7h:-form-item">
                                                                 <button onClick={() => handleNumberChange(50)} type="button" role="radio" aria-checked="false" data-state="unchecked" value="4" class="aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sr-only" id=":r7h:-form-item" aria-describedby=":r7h:-form-item-description" aria-invalid="false" tabindex="0" data-radix-collection-item="">
                                                                     <span data-state="checked" class="flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle h-2.5 w-2.5 fill-current text-current"><circle cx="12" cy="12" r="10"></circle></svg></span></button><input aria-hidden="true" tabindex="-1" type="radio" value="4" className='transform -translate-x-full absolute pointer-events-none opacity-0 m-0 w-16 h-16' checked="" />
                                                                     <div className={`cursor-pointer rounded-lg border border-border px-2 py-1 hover:bg-border sm:px-3 ${selectedNumber === 50 ? 'bg-black text-white' : ''}`}>
                                                                         <h3 class="text-center text-xl">50</h3>
                                                                     </div>
                                                             </label>
                                                         </div>
                                                     </div>
            </div>
          </div>
        )}
        <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button
            onClick={twoFaModel ? closeModal : openTwoFaModel}
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {twoFaModel ? 'Close' : 'Save'}
          </button>
          {!twoFaModel && (
            <button
              onClick={closeModal}
              type="button"
              className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  </div>
)}
                      </div>

)}
      </div>
    </section>
    </>
    
  );
}

export default Users; 
