import React, { useState, useEffect } from 'react';
import '../test.css';
import fwin from '../../Assets/loadingFwin.png';
import { useAuth } from '../Auth/AuthContext';

const FrameLauncher = ({ iframeUrl, closeIframe }) => { 
  const { fetchUserBalance,fwinBackendUrl, email, } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 66000); // 1.10 minutes

    return () => clearTimeout(timer); // Clear the timer if the component unmounts or iframeUrl changes
  }, [iframeUrl]);

  const handleClose = async  () => {
    closeIframe();          // Close the iframe
    fetchUserBalance(email);     // Fetch the updated user balance
    await refreshHistory();    // Wait for history refresh to complete
    await updateWageringProgress();  // After refresh completes, update wagering progress
  };
  const refreshHistory = async () => {
    try {
      // Define the endpoints with their extensions
      const endpoints = [
        `${fwinBackendUrl}/admin/live-history`,
        `${fwinBackendUrl}/admin/slots-history`,
      ];

      // Use Promise.all to fetch all endpoints simultaneously
      const results = await Promise.all(
        endpoints.map((endpoint) =>
          fetch(endpoint).then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json(); // Parse JSON from response
          })
        )
      );

      // Log the fetched data
      console.log("Fetched Data:");
      results.forEach((data, index) => {
        console.log(`Response from ${endpoints[index]}:`, data);
      });

      return results;  // Return the fetched results (you can use it later if needed)

    } catch (error) {
      console.error("Error fetching data:", error.message);
      return null;  // Return null or handle the error as necessary
    }
  };
  const updateWageringProgress = async () => {
    try {
      const response = await fetch(`${fwinBackendUrl}/bets/update-wagering-progress/${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to update wagering progress');
      }

      const data = await response.json();
      console.log('Wagering progress updated:', data.message);
    } catch (error) {
      console.error('Error in updateWageringProgress:', error);
    }
  };

  return (
    <div className='block absolute top-0 z-50 bg-black min-w-[100vw] min-h-[100vh] overflow-hidden'>
      <div className='flex h-[5vh] justify-end items-center bg-gray-800'>
        <div onClick={handleClose} className="cursor-pointer px-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" className="size-8">
            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
          </svg>
        </div>
      </div>
      {loading && (
        <div className="loader">
        <div className="flex justify-center items-center ">
             <div role="status">
               <div>
               <img src={fwin} width="160px" />
               </div>
               <div className='flex justify-center items-center -mt-8'>
               <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                 <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
               </svg>
               <span className="sr-only">Loading...</span>
               </div>
             </div>
           </div>
       </div>
      )}
      <div className={loading ? 'hidden' : ''}>
        <iframe
          src={iframeUrl}
          allowFullScreen
          title="game"
          onLoad={() => setLoading(false)}
          style={{
            height: "95vh",
            width: "100vw"
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default FrameLauncher;
