import React, { useEffect, useState } from "react";
import { getDatabase, ref, query, orderByChild, equalTo, get, update, set } from "firebase/database";
import { v4 as uuidv4 } from "uuid";

const GlobalVisitorTracking = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [serverStatus, setServerStatus] = useState(null); // null indicates not yet checked

  useEffect(() => {
    // Function to fetch and save visitor data
    const fetchAndSaveVisitorData = async () => {
      const db = getDatabase();
      const analyticsRef = ref(db, "visitors");

      try {
        // Fetch IP, city, country, and referrer
        const response = await fetch("https://ipinfo.io?token=dad7740499d695"); 
        const locationData = await response.json();

        if (!locationData || !locationData.ip) {
          throw new Error("Failed to retrieve location data");
        }

        const visitorIP = locationData.ip || "Unknown";
        const visitorCity = locationData.city || "Unknown";
        const visitorCountry = locationData.country || "Unknown";
        const referrer = document.referrer || "Direct";
        const lastVisited = new Date().toISOString();
        const status = isOnline ? "Online" : "Offline"; // Use isOnline state for status

        // Query the database to check if this IP already exists
        const visitorQuery = query(analyticsRef, orderByChild("ip"), equalTo(visitorIP));
        const snapshot = await get(visitorQuery);

        if (snapshot.exists()) {
          // If the visitor already exists, get the key (UUID) and update their data
          const existingVisitorKey = Object.keys(snapshot.val())[0]; // Get the first matching visitor
          const existingVisitorRef = ref(db, `visitors/${existingVisitorKey}`);

          const updatedVisitorData = {
            ip: visitorIP,
            city: visitorCity,
            country: visitorCountry,
            lastVisited: lastVisited,
            status: status,
            referrer: referrer,
          };

          // Update the existing visitor's data
          await update(existingVisitorRef, updatedVisitorData);
          console.log("Visitor data updated for IP:", visitorIP);
        } else {
          // If the visitor doesn't exist, create a new visitor entry
          const newVisitorId = uuidv4(); // Generate a new UUID for the visitor
          const newVisitorRef = ref(db, `visitors/${newVisitorId}`);

          const newVisitorData = {
            ip: visitorIP,
            city: visitorCity,
            country: visitorCountry,
            lastVisited: lastVisited,
            status: status,
            referrer: referrer,
          };

          // Save the new visitor data to Firebase under the new UUID
          await set(newVisitorRef, newVisitorData);
        }
      } catch (error) {
        console.error("Error fetching visitor data:", error);
      }
    };

    // Event handlers for online/offline events
    const handleOnline = () => {
      setIsOnline(true);
      checkServerStatus(); // Verify server connection when coming online
    };

    const handleOffline = () => {
      setIsOnline(false);
      setServerStatus(false); // Mark server status as disconnected
    };

    // Check server status as fallback
    const checkServerStatus = async () => {
      if (!navigator.onLine) return; // Skip if already offline
      try {
        const response = await fetch("https://fwin-backend.onrender.com/ping", {
          method: "HEAD",
          cache: "no-cache", // Avoid caching results
        });
        setServerStatus(response.ok);
      } catch {
        setServerStatus(false); // Fallback to offline if fetch fails
      }
    };

    // Function to handle visibility change (focus and blur)
    const handleVisibilityChange = async () => {
      const db = getDatabase();
      const analyticsRef = ref(db, "visitors");

      try {
        // Fetch IP, city, country, and referrer
        const response = await fetch("https://ipinfo.io?token=dad7740499d695");
        const locationData = await response.json();

        if (!locationData || !locationData.ip) {
          throw new Error("Failed to retrieve location data");
        }

        const visitorIP = locationData.ip || "Unknown";
        const lastVisited = new Date().toISOString();
        const status = document.visibilityState === "visible" ? "Online" : "Offline";

        // Query the database to check if this IP already exists
        const visitorQuery = query(analyticsRef, orderByChild("ip"), equalTo(visitorIP));
        const snapshot = await get(visitorQuery);

        if (snapshot.exists()) {
          // If the visitor exists, get the key (UUID) and update their status
          const existingVisitorKey = Object.keys(snapshot.val())[0];
          const existingVisitorRef = ref(db, `visitors/${existingVisitorKey}`);

          const updatedVisitorData = {
            lastVisited: lastVisited,
            status: status,
          };

          // Update the existing visitor's data with the new status
          await update(existingVisitorRef, updatedVisitorData);
        }
      } catch (error) {
        console.error("Error during visibility change:", error);
      }
    };

    // Function to handle page unload (beforeunload)
    const handleBeforeUnload = async () => {
      const db = getDatabase();
      const analyticsRef = ref(db, "visitors");

      try {
        // Fetch IP, city, country, and referrer
        const response = await fetch("https://ipinfo.io?token=dad7740499d695");
        const locationData = await response.json();

        if (!locationData || !locationData.ip) {
          throw new Error("Failed to retrieve location data");
        }

        const visitorIP = locationData.ip || "Unknown";
        const lastVisited = new Date().toISOString();
        const status = "Offline";

        // Query the database to check if this IP already exists
        const visitorQuery = query(analyticsRef, orderByChild("ip"), equalTo(visitorIP));
        const snapshot = await get(visitorQuery);

        if (snapshot.exists()) {
          // If the visitor exists, get the key (UUID) and update their status to "Offline"
          const existingVisitorKey = Object.keys(snapshot.val())[0];
          const existingVisitorRef = ref(db, `visitors/${existingVisitorKey}`);

          const updatedVisitorData = {
            lastVisited: lastVisited,
            status: status,
          };

          // Update the existing visitor's data with the "Offline" status
          await update(existingVisitorRef, updatedVisitorData);
        }
      } catch (error) {
        console.error("Error during page unload:", error);
      }
    };

    // Add event listeners to handle online/offline events
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    window.addEventListener("focus", handleVisibilityChange);
    window.addEventListener("blur", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Fetch and save visitor data when the component mounts
    fetchAndSaveVisitorData();

    // Cleanup event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("focus", handleVisibilityChange);
      window.removeEventListener("blur", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isOnline]);

  return null; // This component does not render anything
};

export default GlobalVisitorTracking;
