import React, { useEffect, useState } from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import { Routes, Route } from 'react-router-dom';
import Sandbox from './Components/Sandbox';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Loader from './Components/Loader';
import { AuthProvider, useAuth } from './Components/Auth/AuthContext';
import Admin from './Admin/Admin';
import ServerStatus from './Components/ServerStatus';
import IntercomComponent from './Components/IntercomComponent'; // Import Intercom component
import './i18n';
import GlobalVisitorTracking from './Components/Uitils/GlobalVisitorTracking';

const AppContent = () => {
  const [loading, setLoading] = useState(true);
  const { email, userDetails } = useAuth(); // Get email from the Auth Context

  useEffect(() => {
    if (!localStorage.getItem('firstLoadDone')) {
      setLoading(true);
      localStorage.setItem('firstLoadDone', 'true');
    }
    setLoading(false); // Set loading to false after initialization
  }, []);

  const handleLoaderFinish = () => {
    setLoading(false);
  };

  return (
    <div className="App">
      {loading && <Loader onLoaded={handleLoaderFinish} />}
      {!loading && (
        <>
          {/* Initialize Intercom with email */}
          <IntercomComponent email={email} userDetails={userDetails} />
          <GlobalVisitorTracking />

          <Routes>
            <Route path="/" element={<Navbar />} />
            <Route path="/Admin" element={<Admin />} />
            <Route path="/status/server" element={<ServerStatus />} />
            <Route path="/test" element={<Sandbox />} />
          </Routes>
        </>
      )}
    </div>
  );
};

const App = () => (
  <AuthProvider>
    <AppContent />
  </AuthProvider>
);

export default App;
