import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';

const IntercomComponent = ({ email, userDetails }) => {
  
  useEffect(() => {
    let userId;

    if (email) {
      // For logged-in users, use their email or user_id
      userId = userDetails?.userid;  
    } else {
      // For anonymous users, check if a user_id exists in local storage 
      userId = localStorage.getItem('intercom_user_id');
      if (!userId) {
        // Generate a new user_id if not found
        userId = `guest_${Math.random().toString(36).substr(2, 9)}`;
        localStorage.setItem('intercom_user_id', userId);
      }
    }

    const intercomSettings = {
      app_id: 'ftclah49', // Replace with your Intercom App ID ftclah49
      user_id: userId,
      ...(email && {
        email: email,
        name: userDetails?.firstName || 'Guest',
      }),
    };

    // Initialize Intercom
    Intercom(intercomSettings);

    // Cleanup on component unmount
    return () => {
      Intercom('shutdown');
    };
  }, [email, userDetails]);

  return null; // No UI is needed
};

export default IntercomComponent; 
