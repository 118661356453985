import React, { useEffect, useState } from 'react';
import { useAuth } from '../Auth/AuthContext';
import axios from 'axios';

const DepositAnalytics = () => {
  const { email, fwinBackendUrl } = useAuth();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch data from API
  const fetchData = async () => {
    try {
      const response = await axios.get(`${fwinBackendUrl}/payments/deposit-analytics-data`);
      setData(response.data.data);
      // Calculate total pages for pagination
      setTotalPages(Math.ceil(response.data.data.length / itemsPerPage));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Handle pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Slice data to show only the current page
  const currentData = Array.isArray(data) ? data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) : [];

  // Fetch data when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section>
      <div className=''>
        <div className='space-y-5 py-4'>
          <div className="flex items-center gap-2">
            <div className="flex h-6 w-6 items-center justify-center rounded-md border">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-club h-4 w-4">
                <path d="M17.28 9.05a5.5 5.5 0 1 0-10.56 0A5.5 5.5 0 1 0 12 17.66a5.5 5.5 0 1 0 5.28-8.6Z"></path>
                <path d="M12 17.66L12 22"></path>
              </svg>
            </div>
            <h3>Deposit requests</h3>
          </div>
        </div>
        <div>
          <div className="overflow-x-auto min-w-full">
            <table className="min-w-full divide-y divide-gray-200 bg-white border border-gray-200 rounded-lg shadow">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Sl no</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Crypto</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Currency</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Network</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Provider</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentData.length > 0 ? (
                  currentData.map((transaction, index) => (
                    <tr key={transaction._id}>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </td>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">{transaction.email}</td>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">{transaction.amount} EUR</td>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">{transaction.address}</td>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">{transaction.crypto}</td>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">{transaction.currency}</td>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">{transaction.network}</td>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm text-gray-900">{transaction.provider}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" style={{ textAlign: "center" }}>
                      No transaction data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
  
            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="px-4 py-2 bg-gray-200 rounded-md"
              >
                Previous
              </button>
              <span className="text-sm font-medium">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="px-4 py-2 bg-gray-200 rounded-md"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DepositAnalytics;