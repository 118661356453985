import React, { createContext, useState, useEffect, useContext } from 'react';
import Pagination from './Pagination';
import '../test.css';
import FrameLauncher from '../iFrame/iFrame';
import axios from 'axios'; 
import { useAuth } from '../Auth/AuthContext'; 






 

const Help = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
      };
    
      const accordionData = [
        {
          title: "How can I contact support?",
          content:
            "You can contact support through multiple convenient methods. Send us an email at support@fwin-team.freshchat.com, and our team will respond as soon as possible. For immediate assistance, use the live chat widget on our website by clicking the chat icon at the bottom-right corner of any page to connect with an agent in real-time. Alternatively, you can open a support ticket through the Help section on our platform, where you can describe your issue in detail, and our support team will promptly address it.",
        },
        {
          title: "Where is my deposit?",
          content:
            "If your deposit isn't reflecting, it's likely awaiting one or more blockchain confirmations that must occur before your account receives the funds.",
        },
        {
          title: "How long does my withdrawal take?",
          content:
            "Withdrawals are usually processed straight away and will be credited to your account as soon as your transaction is confirmed sufficiently on the blockchain. However in rare cases they may need to be reviewed by our team before being processed. In these cases it can take up to 24 hours.",
        },
        {
          title: "Are there any fees on deposits or withdrawals?",
          content:
            "Fwin does not charge any fees on deposits or withdrawals. You may however have to pay provider or mining fees depending on the method you use to deposit.",
        },
      ];

  

    return ( 
        <div className=''>
            <section>
            <p  className='text-3xl my-4'>Hi! How can we help you?</p>
            <p  className='text-md text-gray-500'>submit your queries here and  we will listen</p>
            <span className='text-blue-500 my-6'>support@fwin.io</span>
            </section>
            <section>
                <section className="py-24">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="mb-16 text-center">
                            <h6 className="text-lg text-indigo-600 font-medium mb-2">FAQs</h6>
                            <h2 className="text-4xl font-manrope font-bold text-gray-900 leading-[3.25rem]">
                                Frequently asked questions
                            </h2>
                        </div>

                        <div className="accordion-group">
                            {accordionData.map((item, index) => (
                                <div
                                    key={index}
                                    className={`accordion py-8 px-6 border-b border-solid border-gray-200 transition-all duration-500 rounded-2xl hover:bg-indigo-50 ${openIndex === index ? "bg-indigo-50" : ""
                                        }`}
                                >
                                    <button
                                        className="accordion-toggle group inline-flex items-center justify-between leading-8 text-gray-900 w-full transition duration-500 text-left hover:text-indigo-600"
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        <h5>{item.title}</h5>
                                        <svg
                                            className={`text-gray-500 transition duration-500 group-hover:text-indigo-600 ${openIndex === index ? "rotate-180 text-indigo-600" : ""
                                                }`}
                                            width="22"
                                            height="22"
                                            viewBox="0 0 22 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                                                stroke="currentColor"
                                                strokeWidth="1.6"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></path>
                                        </svg>
                                    </button>
                                    {openIndex === index && (
                                        <div className="accordion-content w-full px-0 overflow-hidden mt-4">
                                            <p className="font-sans font-normal text-base text-gray-900 leading-6">{item.content}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
};

export default Help;
